.landingLayout {
  background-color: var(--colorWhite);
  display: grid;
  min-height: 100vh;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }

  .left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    // margin-top: 3rem;
    // margin-bottom: 3rem;
    .logoWhiteBgc {
      position: absolute;
      top: 48px;
      left: 0;
      margin-left: 10%;
    }

    .content {
      width: 60%;

      @media (max-width: 768px) {
        width: 80%;
      }
    }
  }
  .right {
    display: flex;
    justify-content: center;
    @media (max-width: 992px) {
      display: none;
    }

    .loginImage {
      max-width: 100%;
      height: 90vh;
    }
  }

  // .content {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   align-items: flex-start;
  //   padding: 10%;
  // }
}
